import React, { useEffect, useState } from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom" 
import ModalNewVille from '../component/modalnewville';
import { NavBar } from "../component/navbar";

const AdministrationVille = () => {
  const n = useNavigate()
  const [villes, setVilles] = useState([]); // Nouvel état pour les villes
  const [searchTerm, setSearchTerm] = useState('');
  const token = localStorage.getItem("token");
  const [typecube, setTypeCube] = useState('');
  const [droit, setDroit] = useState('');
  const [ville, setVille] = useState('');


    async function getDroit() {
        try {
            const droit = localStorage.getItem("username");
          const response = await axios.post('http://cube-calendrier.fr/api/users/need', {username: droit}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          setDroit(response.data.droit);
          setVille(response.data.ville);
          setTypeCube(response.data.typecube)
          console.log(droit, ville, typecube);
        } catch (error) {
          console.log('Error:', error);
        }
      }

     
      async function getVilles() {
        try {
            const response = await axios.get('http://cube-calendrier.fr/api/villes', { // Assurez-vous que la méthode soit GET si vous récupérez les données
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setVilles(response.data); // Mettre à jour l'état avec les données des villes
            console.log(response.data)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          n('/');
       
        }}, []); 



        useEffect(() => {
          const fetchData = async () => {
              await getDroit(); // Attendre la fin de getDroit
          };
          fetchData();
      }, []);
      
      useEffect(() => {
          if (droit === "user") {
              n("/");
          }
          getVilles();
      }, [droit, ville]); 
      

        async function deleteVille(id) {
          try {
            const response = await axios.delete('http://cube-calendrier.fr/api/villes/'+id, {
              credentials: "same-origin",
              headers: {
                'Content-Type': 'application/json', // Définir le type de contenu
                "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                      'Authorization': `Bearer ${token}` 
              }
            });
            console.log(response.data);
            window.location.reload();
          } catch (error) {
            console.log('Error:', error);
          }
        }
        
 
    

        return (
            <div>
              <NavBar isCube={typecube} isDroit={droit} isVille={ville} />
              <ModalNewVille isDroit={droit}/>
              
              <div className="overflow-x-auto p-10">
                <table className="min-w-full bg-white table-auto">
                  <thead>
                    <tr className=''>
                      <th className="w-1/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">ID</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Nom</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Type</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Supprimer</th>

                    </tr>
                  </thead>
                  <tbody>
                    {villes.map(ville => (
                      <tr key={ville.id} className='hover:bg-gray-100'>
                        <td className="px-4 py-2 text-center align-middle">{ville.id}</td>
                        <td className="px-4 py-2 text-center align-middle">{ville.nom}</td>
                        <td className="px-4 py-2 text-center align-middle">{ville.type}</td>
                        <td className="px-4 py-2 text-center align-middle"><button className=" text-white btn btn-error btn-sm ml-2" onClick={() => deleteVille(villes.id)}>Supprimer</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
          
};

export default AdministrationVille;
