import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom" 
import { useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { locale } from '@fullcalendar/core'; // Importez locale
import { NavBar } from "../component/navbar";


const Calendrier = () => {
    const n = useNavigate()
    const [droit, setDroit] = useState('');
    const [ville, setVille] = useState('');
    const [couleur, setCouleur] = useState('');
    const [datejour, setDateJour] = useState('');
    const [datedebut, setDateDebut] = useState('');
    const [typecube, setTypeCube] = useState('');
    const calendarRef = useRef(null);
    const [datefin, setDateFin] = useState('');
    const { city } = useParams(); // Get the city from the URL parameter
  const [events, setEvents] = useState([]);

    const token = localStorage.getItem("token");

    async function getDroit() {
        try {
            const droit = localStorage.getItem("username");
          const response = await axios.post('http://cube-calendrier.fr/api/users/need', {username: droit}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          setDroit(response.data.droit);
          setVille(response.data.ville);
          setTypeCube(response.data.typecube)
	  setCouleur(response.data.couleur)
          console.log(droit, ville);
        } catch (error) {
          console.log('Error:', error);
        }
      }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          n('/');
        }}, []); 



        useEffect(() => {
            getDroit();
        }, []); 



        useEffect(() => {
            axios.get(`http://cube-calendrier.fr/api/Dispo/${city}`)
              .then(response => {
                const calendarEvents = response.data.map(e => ({
                  title: e.qui, 
                  start: e.debut,
                  end: e.fin,
                  color: e.commentaire,
                  id: e.id,
                  
                }));
                setEvents(calendarEvents);
              })
              .catch(error => {
                console.error(`Error fetching calendar events for ${city}:`, error);
              });
          }, [city]); 
        
          async function addDispo() {
            try {
                const username = localStorage.getItem("username")
                const debut = datejour + 'T' + datedebut;
                const fin = datejour + 'T' + datefin;
              const response = await axios.post('http://cube-calendrier.fr/api/Dispos', {qui: username, debut: debut, fin: fin, ville: city, commentaire: couleur}, {
                credentials: "same-origin",
                headers: {
                  'Content-Type': 'application/json', 
                  "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods":
                        "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                        'Authorization': `Bearer ${token}` 
                }
              });
              console.log(response.data);
              window.location.reload();
              
            } catch (error) {
              console.log('Error:', error);
            }
          }
  
          const handleEventClick = (clickInfo) => {
            console.log(clickInfo.event.title)
            if (clickInfo.event.title === localStorage.getItem("username")) {
                if (window.confirm(`Êtes-vous sûr de vouloir supprimer cette disponibilité: ${clickInfo.event.title} ?`)) {
                    deleteEvent(clickInfo.event.id);
                }
            } else {
                alert("Vous ne pouvez supprimer que vos propres disponibilités.");
            }
        };
        
        
        const deleteEvent = async (eventId) => {
            try {
                await axios.delete(`http://cube-calendrier.fr/api/Dispos/${eventId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                window.location.reload()
            } catch (error) {
                console.error('Erreur lors de la suppression:', error);
            }
        };
        
        
        const [calendarView, setCalendarView] = useState('timeGridDay');
  
        const changeView = (view) => {
          calendarRef.current.getApi().changeView(view);
      };

        return (
            <div>
              <NavBar isCube={typecube} isDroit={droit} isVille={ville} />
              <div className="mb-4 flex flex-col sm:flex-row justify-center gap-4 sm:pt-10 items-center">
                    <div className="mr-2">
                        <label className="mb-2 text-sm font-medium text-gray-700" htmlFor="selectedDay">Jour :</label>
                        <input 
                            type="date" 
                            id="selectedDay" 
                            onChange={(e) => setDateJour(e.target.value)}
                            required 
                            className="p-2 border border-gray-300 rounded-md w-auto"
                        />
                    </div>
                    <div className="mr-2">
                        <label className="mb-2 text-sm font-medium text-gray-700" htmlFor="startTime">Heure de début :</label>
                        <input 
                            type="time" 
                            id="startTime" 
                            onChange={(e) => setDateDebut(e.target.value)}
                            required 
                            className="p-2 border border-gray-300 rounded-md w-auto"
                        />
                    </div>
                    <div className="mr-2">
                        <label className="mb-2 text-sm font-medium text-gray-700" htmlFor="endTime">Heure de fin :</label>
                        <input 
                            type="time" 
                            id="endTime" 
                            onChange={(e) => setDateFin(e.target.value)}
                            required 
                            className="p-2 border border-gray-300 rounded-md w-auto"
                        />
                    </div>
                    <button onClick={addDispo} className="btn btn-primary text-sm sm:text-base md:btn-md lg:btn-lg">Ajouter</button>
                </div>
                <div className="buttons">
            <button onClick={() => changeView('timeGridDay')} className="btn btn-day">Vue Journalière</button>
            <button onClick={() => changeView('timeGridWeek')} className="btn btn-week">Vue Hebdomadaire</button>
        </div>
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView={calendarView}
            events={events}
            ref={calendarRef}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            allDaySlot={false}
            eventClick={handleEventClick}
            locale='fr'
            firstDay={1}
        />
    </div>
        );
        
          
};

export default Calendrier;
