import logo from "../img/cube.jpg"
import axios from "axios";
import React from "react";

export default function ModalModifUser({isDroit, userData}){




    const [username, setUsername] = React.useState(userData.username || "");
    const [password, setPassword] = React.useState(userData.mdp || "");
    const [entite, setEntite] = React.useState(userData.typecube || "");
    const [droite, setDroite] = React.useState(userData.droit || "");
    const [couleur, setCouleur] = React.useState(userData.couleur || "");
    const [ville, setVille] = React.useState(userData.ville ? userData.ville.split(', ') : []);
    const token = localStorage.getItem("token");
    const [availableVilles, setAvailableVilles] = React.useState([]);


    const handleVilleChange = (event) => {
        const value = event.target.value;
        setVille(currentVilles =>
          currentVilles.includes(value)
            ? currentVilles.filter(villeId => villeId !== value) 
            : [...currentVilles, value]
        );
      };
      

      console.log(isDroit)
    
      const villeString = ville.sort().join('');

      async function updateUser() {
        try {
          const villeString = ville.sort().join(', ');
          const response = await axios.put(`http://cube-calendrier.fr/api/users/${userData.id}`, {
            username:username,
            typecube: entite,
            droit: droite,
            ville: villeString,
            couleur: couleur

          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
            }
          });
          window.location.reload()
          console.log(response.data);
        } catch (error) {
          console.log('Error:', error);
        }
      }


      const modalId = `my-modal-${userData.id}`;


      React.useEffect(() => {
        async function fetchVilles() {
            try {
                const response = await axios.get('http://cube-calendrier.fr/api/villes', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAvailableVilles(response.data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchVilles();
      }, [token]);


      const villeCheckboxes = availableVilles
  .filter(villeItem => {
      if (entite === 'ALL') return true;
      if (entite === 'ESC' && (villeItem.type === 'escape' || villeItem.type === 'both')) return true;
      if (entite === 'TV' && (villeItem.type === 'tv' || villeItem.type === 'both')) return true;
      return false;
  })
  .map((villeItem) => (
      <div key={villeItem.id} className="flex flex-col items-center">
          <input
              type="checkbox"
              id={`ville-${villeItem.id}`}
              name="ville"
              value={villeItem.id}
              onChange={handleVilleChange}
              checked={ville.includes(villeItem.id.toString())}
          />
          <label htmlFor={`ville-${villeItem.id}`}>{villeItem.nom}</label>
      </div>
  ));

    

    return(
        <div>

            <label htmlFor={modalId} data-testid="signin" className=" btn h-8 rounded-lg text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2  align-middle focus:outline-none focus:shadow-outline mr-2">Modifier utilisateur</label>  
       
            <input type="checkbox" id={modalId} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box ">
                    <div className="flex flex-col items-center justify-center space-y-5">
                        <img className="w-32" src={logo}></img><br></br>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Nom utilisateur</span>
                            </label>
                            <input name="email" defaultValue={userData.username} required data-testid="username" type="text" onChange={(e) => setUsername(e.target.value)} placeholder="Saisir nom utilisateur" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Couleur</span>
                            </label>
                            <input name="couleur" defaultValue={userData.couleur} required data-testid="couleur" type="color" onChange={(e) => setCouleur(e.target.value)} placeholder="couleur" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Droit</span>
                            </label>
                            <select name="droit" defaultValue={userData.droit} required data-testid="droit"  onChange={(e) => setDroite(e.target.value)} className="select select-bordered border-[#648DE5] w-full max-w-xs">
                                <option disabled selected>Quel droit pour l'utilisateur ?</option>
                                {isDroit === "SA" && <option value="SA">SuperAdmin</option>}
                                {(isDroit == "SA" || isDroit == "A") &&  <option value={"A"}>Admin</option>}
                                {(isDroit == "SA" || isDroit == "A" || isDroit == "AESC") && <option value={"AESC"}>CDI Escape</option>}
                                {(isDroit == "SA" || isDroit == "A" || isDroit == "ATV") && <option value={"ATV"}>CDI TV</option>}
                                <option value={"user"}>Extra</option>


                            </select>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Entité</span>
                            </label>
                            <select name="entite" defaultValue={userData.typecube} required data-testid="entite"  onChange={(e) => setEntite(e.target.value)} className="select select-bordered border-[#648DE5] w-full max-w-xs">
                                <option disabled selected>Quel type de cube ?</option>
                                <option value={"ESC"}>Cube Escape</option>
                                <option value={"TV"}>CubeTV</option>
                                <option value={"ALL"}>Admin (les 2)</option>
                            </select>
                        </div>
                        <div className="flex flex-wrap gap-8 justify-center"> 
                            {villeCheckboxes}
                        </div>
                            <button onClick={updateUser} type="button" className="btn bg-[#648DE5] border-transparent">Modifier utilisateur</button>                    <div className="modal-action">
                        <label htmlFor={modalId} className="btn">Close</label>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}