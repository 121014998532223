import React, { useEffect, useState } from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom" 
import ModalNewUser from '../component/modalnewuser';
import ModalModifUser from '../component/modalmodifuser';
import { NavBar } from "../component/navbar";
import { Link } from "react-router-dom";


const Administration = () => {
    const n = useNavigate()
    const [droit, setDroit] = useState('');
    const [user, setUser] = useState([]);
    const [ville, setVille] = useState('');
    const [typecube, setTypeCube] = useState('');
    const [searchTerm, setSearchTerm] = useState('');


    const token = localStorage.getItem("token");




    async function getDroit() {
        try {
            const droit = localStorage.getItem("username");
          const response = await axios.post('http://cube-calendrier.fr/api/users/need', {username: droit}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          setDroit(response.data.droit);
          setVille(response.data.ville);
          setTypeCube(response.data.typecube)
          console.log(droit, ville, typecube);
        } catch (error) {
          console.log('Error:', error);
        }
      }

      async function getUserbyVille() {
        try {
          const response = await axios.post('http://cube-calendrier.fr/api/users/ville', {ville: ville}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          setUser(response.data);
          console.log(response.data);
        } catch (error) {
          console.log('Error:', error);
        }
      }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          n('/');
       
        }}, []); 



        useEffect(() => {
          const fetchData = async () => {
              await getDroit(); // Attendre la fin de getDroit
          };
          fetchData();
      }, []);
      
      useEffect(() => {
          if (droit === "user") {
              n("/");
          }
          // Si 'ville' est définie, récupérer les utilisateurs associés
          if (ville) {
              getUserbyVille();
          }
      }, [droit, ville]); // Ajoutez 'droit' et 'ville' comme dépendances
      
      const filteredUsers = user.filter(utilisateur => {
        return utilisateur.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
               utilisateur.droit.toLowerCase().includes(searchTerm.toLowerCase()) ||
               utilisateur.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
               utilisateur.typecube.toLowerCase().includes(searchTerm.toLowerCase());
    });

        async function deleteUser(id) {
          try {
            const response = await axios.delete('http://cube-calendrier.fr/api/users/'+id, {
              credentials: "same-origin",
              headers: {
                'Content-Type': 'application/json', // Définir le type de contenu
                "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                      'Authorization': `Bearer ${token}` 
              }
            });
            console.log(response.data);
            window.location.reload();
          } catch (error) {
            console.log('Error:', error);
          }
        }
        
 
      
 
    

        return (
            <div>
              <NavBar isCube={typecube} isDroit={droit} isVille={ville} /> <br></br>
              {(droit == "SA") && <Link to="/administration/ville"><center><button className="btn btn-primary">Gérer les Villes</button></center></Link>}
              <ModalNewUser isDroit={droit}/>
              <div className="overflow-x-auto p-10">
                <input type="text" placeholder="Rechercher..."  value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="form-input px-4 h-12 text-lg border-4 border-gray-400 rounded-lg shadow-md focus:outline-none focus:border-blue-500 transition duration-300 mx-auto w-full max-w-lg"/>
                <table className="min-w-full bg-white table-auto">
                  <thead>
                    <tr className=''>
                      <th className="w-1/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">ID</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Utilisateur</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Droit</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Entité</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Ville</th>
                      <th className="w-1/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Couleur</th>
                      <th className="w-2/12 py-2 px-4 bg-gray-200 font-bold uppercase text-sm text-grey-dark border-b border-grey-light">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map(utilisateur => (
                      <tr key={utilisateur.id} className='hover:bg-gray-100'>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.id}</td>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.username}</td>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.droit}</td>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.typecube}</td>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.ville}</td>
                        <td className="px-4 py-2 text-center align-middle">{utilisateur.couleur}</td>
                        <td className="px-4 py-2 text-center align-middle">
                        { (droit === 'SA') || 
                          (droit === 'A' && utilisateur.droit !== 'SA') || 
                          (droit === 'AESC' && (utilisateur.droit === 'AESC' || utilisateur.droit === 'user')) ||
                          (droit === 'ATV' && (utilisateur.droit === 'ATV' || utilisateur.droit === 'user'))
                          ? (
                              <>
                                <ModalModifUser isDroit={droit} userData={utilisateur} />
                                <button className=" text-white btn btn-error btn-sm ml-2" onClick={() => deleteUser(utilisateur.id)}>Supprimer</button>
                              </>
                            ) 
                          : null
                        }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
          
};

export default Administration;
