import logo from "../img/cube.jpg"
import axios from "axios";
import React from "react";

export default function ModalNewVille({isDroit}){




    const [nom, setNom] = React.useState("");
    const [type, setType] = React.useState("");
    const [ville, setVille] = React.useState([]);
    const token = localStorage.getItem("token");


    const handleVilleChange = (event) => {
        const value = event.target.value;
        setVille(currentVilles =>
          currentVilles.includes(value)
            ? currentVilles.filter(villes => villes !== value) 
            : [...currentVilles, value] 
        );
      };

    
      const villeString = ville.sort().join('');


      async function addVille() {
        try {
          const response = await axios.post('http://cube-calendrier.fr/api/villes', {nom: nom, type: type}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          console.log(response.data);
          window.location.reload();
          
        } catch (error) {
          console.log('Error:', error);
        }
      }



    

    return(
        <div>
            <div className="flex justify-center pt-28">
            <label htmlFor="my-modal" data-testid="signin" className="btn bg-green-500 hover:bg-green-600">Créer utilisateur</label>  
            </div>
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box ">
                    <div className="flex flex-col items-center justify-center space-y-5">
                        <img className="w-32" src={logo}></img><br></br>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Nom ville</span>
                            </label>
                            <input name="nom" required data-testid="nom" type="nom" onChange={(e) => setNom(e.target.value)} placeholder="Saisir nom ville" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Type</span>
                            </label>
                            <select name="type" required data-testid="type"  onChange={(e) => setType(e.target.value)} className="select select-bordered border-[#648DE5] w-full max-w-xs">
                                <option disabled selected>Quel type de cube ?</option>
                                <option value={"escape"}>Cube Escape</option>
                                <option value={"tv"}>CubeTV</option>
                                <option value={"both"}>Les 2</option>
                            </select>
                        </div>
                        <button data-testid="btnsignin" onClick={addVille} type="button" className="btn bg-[#648DE5] border-transparent">Créer utilisateur</button>
                    <div className="modal-action">
                        <label htmlFor="my-modal" className="btn">Close</label>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}