import logo from "../img/cube.jpg"
import axios from "axios";
import React from "react";


export default function ModalNewUser({isDroit}){


    const [availableVilles, setAvailableVilles] = React.useState([]);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [entite, setEntite] = React.useState("");
    const [ville, setVille] = React.useState([]);
    const [droite, setDroite] = React.useState("");
    const [couleur, setCouleur] = React.useState("");
    const token = localStorage.getItem("token");


    const handleVilleChange = (event) => {
        const value = parseInt(event.target.value, 10); // Convertir la valeur en nombre
        setVille(currentVilles =>
            currentVilles.includes(value)
                ? currentVilles.filter(villeId => villeId !== value) 
                : [...currentVilles, value]
        );
    };
    

    
      const villeString = ville.sort().join('');


      async function addUser() {
        try {
          const response = await axios.post('http://cube-calendrier.fr/api/register', {username: username, mdp: password, typecube: entite, droit: droite, ville: villeString, couleur: couleur}, {
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json', // Définir le type de contenu
              "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}` 
            }
          });
          console.log(response.data);
          window.location.reload();
          
        } catch (error) {
          console.log('Error:', error);
        }
      }



      React.useEffect(() => {
        async function fetchVilles() {
            try {
                const response = await axios.get('http://cube-calendrier.fr/api/villes', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAvailableVilles(response.data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchVilles();
    }, [token]);


    const villeCheckboxes = availableVilles
    .filter(villeItem => {
        if (entite === 'ALL') return true; // toutes les villes pour 'ALL'
        if (entite === 'ESC' && (villeItem.type === 'escape' || villeItem.type === 'both')) return true; // villes 'escape' et 'both' pour 'ESC'
        if (entite === 'TV' && (villeItem.type === 'tv' || villeItem.type === 'both')) return true; // villes 'tv' et 'both' pour 'TV'
        return false;
    })
    .map((villeItem) => (
        <div key={villeItem.id} className="flex flex-col items-center">
            <input
                type="checkbox"
                id={`ville-${villeItem.id}`}
                name="ville"
                value={villeItem.id}
                onChange={handleVilleChange}
                checked={ville.includes(villeItem.id)}
            />
            <label htmlFor={`ville-${villeItem.id}`}>{villeItem.nom}</label>
        </div>
    ));

    return(
        <div>
            <div className="flex justify-center pt-28">
            <label htmlFor="my-modal" data-testid="signin" className="btn bg-green-500 hover:bg-green-600">Créer utilisateur</label>  
            </div>
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box ">
                    <div className="flex flex-col items-center justify-center space-y-5">
                        <img className="w-32" src={logo}></img><br></br>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Nom utilisateur</span>
                            </label>
                            <input name="email" required data-testid="username" type="mail" onChange={(e) => setUsername(e.target.value)} placeholder="Saisir nom utilisateur" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Mot de passe</span>
                            </label>
                            <input name="password" required type="password" data-testid="mdp"  onChange={(e) => setPassword(e.target.value)} placeholder="Saisir mdp" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Couleur</span>
                            </label>
                            <input name="couleur" required data-testid="couleur" type="color" onChange={(e) => setCouleur(e.target.value)} placeholder="couleur" className="input input-bordered border-[#648DE5] w-full max-w-xs"/>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Droit</span>
                            </label>
                            <select name="droit" required data-testid="droit"  onChange={(e) => setDroite(e.target.value)} className="select select-bordered border-[#648DE5] w-full max-w-xs">
                                <option disabled selected>Quel droit pour l'utilisateur ?</option>
                                {isDroit === "SA" && <option value="SA">SuperAdmin</option>}
                                {(isDroit == "SA" || isDroit == "A") &&  <option value={"A"}>Admin</option>}
                                {(isDroit == "SA" || isDroit == "A" || isDroit == "AESC") && <option value={"AESC"}>CDI Escape</option>}
                                {(isDroit == "SA" || isDroit == "A" || isDroit == "ATV") && <option value={"ATV"}>CDI TV</option>}
                                <option value={"user"}>Extra</option>

                            </select>
                        </div>
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                            <span className="label-text">Entité</span>
                            </label>
                            <select name="entite" required data-testid="entite"  onChange={(e) => setEntite(e.target.value)} className="select select-bordered border-[#648DE5] w-full max-w-xs">
                                <option disabled selected>Quel type de cube ?</option>
                                <option value={"ESC"}>Cube Escape</option>
                                <option value={"TV"}>CubeTV</option>
                                <option value={"ALL"}>Admin (les 2)</option>
                            </select>
                        </div>
                        <div className="flex flex-wrap gap-8 justify-center"> 
                            {villeCheckboxes}
                        </div>
                   

                        <button data-testid="btnsignin" onClick={addUser} type="button" className="btn bg-[#648DE5] border-transparent">Créer utilisateur</button>
                    <div className="modal-action">
                        <label htmlFor="my-modal" className="btn">Close</label>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}