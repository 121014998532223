import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from '../img/cube.jpg';
import logoTV from '../img/cubetv.png';
import logolmdc from '../img/lmdc.png';
import { Link } from "react-router-dom";
import { NavBar } from "../component/navbar";

const Index = () => {
    const navigate = useNavigate();
    const [droit, setDroit] = useState('');
    const [ville, setVille] = useState('');
    const [typecube, setTypeCube] = useState('');
    const [villeRedirection, setVilleRedirection] = useState('');

    const token = localStorage.getItem("token");

    const [showImages, setShowImages] = useState(false);
    const [showTVImages, setShowTVImages] = useState(false);

    const toggleImages = () => setShowImages(!showImages);
    const toggleTVImages = () => setShowTVImages(!showTVImages);

    async function getDroit() {
        try {
            const username = localStorage.getItem("username");
            const response = await axios.post('http://cube-calendrier.fr/api/users/need', { username }, {
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}`
                }
            });
            setDroit(response.data.droit);
            setVille(response.data.ville);
            setTypeCube(response.data.typecube);
            console.log(droit, ville, typecube);
        } catch (error) {
            console.log('Error:', error);
        }
    }

    async function getVille() {
        try {
            const idVille = ville;
            const response = await axios.get(`http://cube-calendrier.fr/api/Villes/${idVille}`, {
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                    'Authorization': `Bearer ${token}`
                }
            });
            setVilleRedirection(response.data.nom);
        } catch (error) {
            console.log('Error:', error);
        }
    }

    useEffect(() => {
        getDroit();
    }, []);
    
    useEffect(() => {
        if (droit === 'user' && ville) {
            getVille();
        }
    }, [droit, ville]);
    
    useEffect(() => {
        if (droit === 'user' && villeRedirection) {
            navigate(`/calendrier/${villeRedirection}`);
        }
    }, [droit, villeRedirection, navigate]);

        return (
            <div>
              <NavBar isCube={typecube} isDroit={droit} isVille={ville} />
                <div className="flex flex-col justify-center items-center mt-4">
                    <div className="flex gap-4 sm:gap-20 justify-center items-center flex-col sm:flex-row">
                        {(droit == "SA" || droit == "A" || droit == "AESC") && (
                            <div>
                                <img className='w-48 sm:w-96 rounded-3xl' onClick={toggleImages} src={logo} alt="Logo principal" />
                            </div>)}
                        {(droit == "SA" || droit == "A" || droit == "ATV") && (
                            <div>
                                <img className='w-48 sm:w-96 rounded-3xl' onClick={toggleTVImages} src={logoTV} alt="Logo TV" />
                            </div>)}
                    </div>
                    {showImages && (
                        <div className="flex flex-wrap justify-center gap-4 sm:gap-32 items-center pt-4 sm:pt-24">
                            {ville.includes("7") && (<div className='text-center'>
                                <Link to={"/calendrier/lmdc"} ><img className='w-24 sm:w-48 rounded-3xl mx-auto' src={logolmdc} alt="LMDC" /></Link>
                                <span className='text-sm font-semibold'>LMDC</span>
                            </div>)}
                            {ville.includes("1") && (<div className='text-center'>
                            <Link to={"/calendrier/nancyescape"} ><img className='w-24 sm:w-48 rounded-3xl mx-auto' src={logo} alt="Nancy" /></Link>
                                <span className='text-sm font-semibold'>Nancy</span>
                            </div>)}
                            {ville.includes("2") && (<div className='text-center'>
                            <Link to={"/calendrier/nice"} ><img className='w-24 sm:w-48 rounded-3xl mx-auto' src={logo} alt="Nice" /></Link>
                                <span className='text-sm font-semibold'>Nice</span>
                            </div>)}
                        </div>
                    )}
                    {showTVImages && (
                        <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-8 pt-4 sm:pt-24">
                            {ville.includes("1") && (<div className='text-center'>
                            <Link to={"/calendrier/nancytv"} ><img className='w-24 sm:w-48 rounded-3xl mx-auto' src={logoTV} alt="LMDC" /></Link>
                                <span className='text-sm font-semibold'>Nancy</span>
                            </div>)}
                        </div>
                    )}
                </div>
            </div>
        );
        
          
};

export default Index;
