import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Login from "./page/login";
import Index from "./page/index"
import Administration from "./page/administration";
import AdministrationVille from "./page/administration_ville";

import Calendrier from "./page/calendrier";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/index" element={<Index />} />
        <Route path="/administration" element={<Administration />} />
        <Route path="/administration/ville" element={<AdministrationVille />} />
        <Route path="/calendrier/:city" element={<Calendrier />} />  
        </Routes>
    </Router>
  );
}

export default App;
