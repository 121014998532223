import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from '../img/cube.jpg';
import { useNavigate } from "react-router-dom";

export const NavBar = ({ isDroit, isVille, isCube }) => {
  const navigate = useNavigate();
  const [availableVilles, setAvailableVilles] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchVilles() {
      try {
        const response = await axios.get('http://cube-calendrier.fr/api/villes', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setAvailableVilles(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchVilles();
  }, [token]);

  const disconnect = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/");
  };

  const GoToHome = () => {
    navigate("/index");
  };

  const nancyLink = (cubeType) => {
    const nancy = availableVilles.find(ville => ville.nom.toLowerCase() === 'nancy');
    if (!nancy || !isVille.includes(nancy.id.toString())) return null;

    let pathSuffix = '';
    if (cubeType === 'ESC' || cubeType === 'ALL') pathSuffix = 'escape';
    else if (cubeType === 'TV') pathSuffix = 'tv';

    return (
      <li key={nancy.id}>
        <Link to={`/calendrier/nancy${pathSuffix}`}>
          <a>Nancy</a>
        </Link>
      </li>
    );
  };

  const villeLinks = (cubeType) => {
    return availableVilles
      .filter(ville => ville.type === cubeType || ville.type === 'both')
      .map(ville => {
        // Gérer spécifiquement Nancy pour les liens Cube Escape et CubeTV
        if (ville.nom.toLowerCase() === 'nancy') {
           if (cubeType === 'tv' && isVille.includes(ville.id.toString())) {
            return (
              <li key={ville.id}>
                <Link to="/calendrier/nancytv">
                  <a>Nancy TV</a>
                </Link>
              </li>
            );
          }
          return null; // Ne pas afficher le lien si Nancy n'est pas gérée pour le cubeType actuel
        } else {
          // Générer des liens pour les autres villes
          return isVille.includes(ville.id.toString()) ? (
            <li key={ville.id}>
              <Link to={`/calendrier/${ville.nom.toLowerCase().replace(/\s+/g, '')}`}>
                <a>{ville.nom}</a>
              </Link>
            </li>
          ) : null;
        }
      });
  };

  return (
    <div className="navbar bg-[#02A4D4] h-20 shadow-md z-50">
      <img src={logo} alt="logo" onClick={GoToHome} className="h-16 cursor-pointer" />

      <div className="flex-1 px-2 mx-2">
        {(isCube === "ESC" || isCube === "ALL") && (
          <div className="dropdown">
            <label tabIndex="0" className="btn m-1">Cube Escape</label>
            <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              {nancyLink('ESC')}
              {villeLinks('escape')}
            </ul>
          </div>
        )}
        {(isCube === "TV" || isCube === "ALL") && (
          <div className="dropdown">
            <label tabIndex="0" className="btn m-1">CubeTV</label>
            <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              {isCube === "TV" ? nancyLink('TV') : null}
              {villeLinks('tv')}
            </ul>
          </div>
        )}
      </div>

      <div className="flex-none">
        {(isDroit === "SA" || isDroit === "A" || isDroit === "AESC" || isDroit === "ATV") && (
          <Link to="/administration">
            <button className="btn text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded-xl">
              Administration
            </button>
          </Link>
        )}
        <button onClick={disconnect} className="btn text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded-xl ml-2">
          Déconnexion
        </button>
      </div>
    </div>
  );
};
